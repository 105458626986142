<div class="background">
    <br>
<h1 class="header">EU-West Serverstatus:</h1>
<div class="grid-container">
<div *ngIf="items[0].status=='online'" class="grid-item">Spiel:<br><a class="online">{{items[0].status}}</a></div>
<div *ngIf="items[0].status=='offline'" class="grid-item">Spiel:<br><a class="offline">{{items[0].status}}</a></div>
<div *ngIf="items[1].status=='online'" class="grid-item">Shop:<br><a class="online">{{items[1].status}}</a></div>
<div *ngIf="items[1].status=='offline'" class="grid-item">Shop:<br><a class="offline">{{items[1].status}}</a></div>
<div *ngIf="items[3].status=='online'" class="grid-item">Client:<br><a class="online">{{items[3].status}}</a></div>
<div *ngIf="items[3].status=='offline'" class="grid-item">Client:<br><a class="offline">{{items[3].status}}</a></div>
<div *ngIf="items[2].status=='online'" class="grid-item">Website:<br><a class="online">{{items[2].status}}</a></div>
<div *ngIf="items[2].status=='offline'" class="grid-item">Website:<br><a class="offline">{{items[2].status}}</a></div>
</div>
</div>