<header *ngIf="!auth" class="header">
    <button mat-raised-button id="menu-button"[matMenuTriggerFor]="menu">Menu
    </button>
<mat-menu #menu="matMenu">
  <button routerLink="" mat-menu-item>Home</button>
  <button routerLink="/secret" mat-menu-item>Serverstatus</button>
  <button routerLink="/champion" mat-menu-item>Leaderboard</button>
</mat-menu>

</header>
<div class="login" *ngIf="auth">
    <ngx-auth-firebaseui-login 
    (onSuccess)="printUser()"
    [providers]="[providers.Google]"
    [registrationEnabled]="false"
    [resetPasswordEnabled]="false"
    appearance="legacy"
    >
</ngx-auth-firebaseui-login>


Wenn sie sich nicht mit Google anmelden wollen, können sie folgende Email und Passwort nutzen: 
<br><br>
Email: geheimnis@lukas-roeding.de<br><br>Passwort: Geheimnis</div>
<router-outlet *ngIf="!auth"></router-outlet>